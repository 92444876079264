import './mainNav.css'
import { motion } from 'framer-motion'

const MainNav = () => {

    const Search = <svg role="img" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" aria-labelledby="searchIconTitle" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" fill="none" color="white"> <title id="searchIconTitle">Search</title> <path d="M14.4121122,14.4121122 L20,20"/> <circle cx="10" cy="10" r="6"/> </svg>

    const nameVAR = {
        hidden:{
            y: '-50',
            opacity: 0,
        },
        visible:{
            y: 0,
            opacity: 1,
            transition:{
                delay: .2,
                type: 'spring',
                stiffness: 300
            }
        }
        
    }

    const honeVAR = {
        hidden:{
            x: '-50',
            opacity: 0,
            color: 'black',
        },
        visible:{
            x: 0,
            opacity: 1,
            color: 'orangered',
            transition:{
                delay: .2,
                type: 'spring',
                stiffness: 300,
                color:{
                    delay: 2,
                    duration: 3
                }
            }
        }
    }

    const searchVAR = {
        hidden:{
            opacity: 0,
        },
        visible:{
            opacity: 1,
            transition:{
                delay: .3,
                duration: 2
            }
        }
    }

    const circleVAR = {
        hidden:{
            opacity: 0,
            scale: .8,
        },
        visible:{
            scale: 1.05,
            opacity: 1,
            transition:{
                delay: 1,
                duration: 2,
                scale:{
                    delay: 2,
                    duration: 2,
                }, 
            }
        }
    }

    return ( 
        <main>
            <div className="container">
                <div className="top">
                    <div className="left">
                        <motion.h1
                        variants={honeVAR}
                        initial="hidden"
                        animate="visible"
                        >MoviePot</motion.h1>
                    </div>
                    <div className="right">
                        <motion.div className="search"
                        variants={searchVAR}
                        initial="hidden"
                        animate="visible"
                        >
                            {Search}
                            <input type="text" placeholder="Search MoviePot"/>
                        </motion.div>
                        <div className="profile">
                            <motion.div className="circle"
                            variants={circleVAR}
                            initial="hidden"
                            animate="visible"
                            ></motion.div>
                            <motion.div className="name"
                            variants={nameVAR}
                            initial="hidden"
                            animate="visible"
                            whileHover={{scale: 1.05, color: 'red'}}
                            >Denzel Malcom McConor</motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
     );
}
 
export default MainNav;