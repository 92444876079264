import {  NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import './sideNavBody.css'
import Title from './title';
import { motion } from 'framer-motion'
import Movies from './movies';

const SideNavBody = ({title}) => {
   const linksVAR = {
    hidden:{
        opacity: 0,

    },
    visible:{
        opacity: 1,
        transition:{
            delay: 1,
            when: 'beforeChildren',
            staggerChildren: .2,
        }
    }
   }
    const linkVAR = {
    hidden:{
        x: '-300',
        opacity: 0,
    },
    visible:{
        x: 0,
        opacity: 1,
        transition:{
            type: 'spring',
            stiffness: 50
        }
    }
   }
 
        return ( 
        <main>
            <div className="cont">
                <div className="sidenav">
                    <motion.div className="links"
                    variants={linksVAR}
                    initial="hidden"
                    animate="visible"
                    >
                        <div className="link"><motion.p whileHover={{scale: 1.3, originX: 0 }}
                        transition={{type: 'spring', stiffness: 500,}}
                        variants={linkVAR}
                        ><NavLink to="/">New Releases</NavLink></motion.p></div>
                        
                        <div className="link"><motion.p whileHover={{scale: 1.3, originX: 0 }}
                        transition={{type: 'spring', stiffness: 300,}}
                        variants={linkVAR}
                       
                        ><NavLink to="/trending">Trending</NavLink></motion.p></div>

                        <div className="link"><motion.p whileHover={{scale: 1.3, originX: 0 }}
                        transition={{type: 'spring', stiffness: 300,}}
                        variants={linkVAR}
                       
                        ><NavLink  to="/comingsoon">Coming Soon</NavLink></motion.p></div>

                        <div className="link"><motion.p whileHover={{scale: 1.3, originX: 0 }}
                        transition={{type: 'spring', stiffness: 300,}}
                        variants={linkVAR}
                       
                        ><NavLink  to="/favourites">Favourites</NavLink></motion.p></div>

                        <div className="link"><motion.p whileHover={{scale: 1.3, originX: 0 }}
                        transition={{type: 'spring', stiffness: 300,}}
                        variants={linkVAR}
                      
                        ><NavLink  to="/watchlater">Watch Later</NavLink></motion.p></div>
                    </motion.div>
                </div>
                <div className="body">
                    <Title title={title}/>
                    <Movies />
                </div>
            </div>
        </main>
     );
}
 

export default SideNavBody;