import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ComingSoon from "./pages/comingsoon";
import Favourites from "./pages/favourites";
import Homepage from "./pages/homepage";
import Trending from "./pages/trending";
import WatchLater from "./pages/watchlater";

const App = () => {
    return ( 
        <Router>
          <main className="app">               
            <Switch>
              <Route  exact path="/">
                <Homepage  title="New Releases"/>
              </Route>
              
              <Route exact path="/trending">
                <Trending  title="Trending"/>
              </Route>


              <Route exact path="/comingsoon">
                <ComingSoon title="Coming Soon"/>
              </Route>

              <Route exact path="/favourites">
                <Favourites  title="Favourites"/>
              </Route>

               <Route exact path="/watchlater">
                <WatchLater title={"Watch Later"}/>
              </Route>

              <Route path="*">

              </Route>
            </Switch>
          </main>
          </Router>
   );
}
export default App;
 