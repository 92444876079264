import MainNav from "../components/mainNav";
import SideNavBody from "../components/sideNavBody";

const Trending = ({title}) => {
    return ( 
        <main>
            <MainNav />
            <SideNavBody title={title}/>
        </main>
     );
}
 
export default Trending;