import './movies.css'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import MeTime from '../images/images/metime.jpg'
import Sandman from '../images/images/sandman.jpg'
import Samaritan from '../images/images/samaritan.jpg'
import Beast from '../images/images/beast.jpg'
import DayShift from '../images/images/dayshift.jpg'
import Bodies from '../images/images/bodies.jpg'
import Carter from '../images/images/carter.jpg'
import Prey from '../images/images/prey.jpg'
import Echoes from '../images/images/echoes.jpg'
import See from '../images/images/see.jpg'



const Movies = ({}) => {
  





    const boxesVAR = {
        hidden:{
            opacity: .3,
        },
        visible:{
            opacity: 1,
            transition:{
                delay: .5,
                duration: 1,
                when: 'beforeChildren',
                staggerChildren: .3

            }
        }
    }
    const boxVAR = {
        hidden:{
            opacity: 0,
        },
        visible:{
            opacity: 1,
            transition:{
            }
        }
    }

    return ( 
        <main>
            <div className="movies">
                <motion.div className="boxes"
                variants={boxesVAR}
                initial="hidden"
                animate="visible"
                >
                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={MeTime} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Me Time</div>
                                <div className="genre">Comedy</div>
                            </div>
                        
                        </motion.div>

                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={Sandman} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Sandman</div>
                                <div className="genre">Supernatural</div>
                            </div>
                        
                        </motion.div>


                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={Samaritan} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Samaritan</div>
                                <div className="genre">Adventure, Comic</div>
                            </div>
                        
                        </motion.div>
                        
                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={Beast} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Beast</div>
                                <div className="genre">Fantasy, Horror</div>
                            </div>
                        
                        </motion.div>


                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={DayShift} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Day Shift</div>
                                <div className="genre">Comedy</div>
                            </div>
                        
                        </motion.div>

                        <motion.div className="box" variants={boxVAR}>
                        
                        <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={Carter} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Carter</div>
                                <div className="genre">Adventure</div>
                            </div>
                        
                        </motion.div>

                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={Prey} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Prey</div>
                                <div className="genre">Comic</div>
                            </div>
                        
                        </motion.div>

                         <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={Bodies} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Bodies</div>
                                <div className="genre">Horror</div>
                            </div>
                        
                        </motion.div>

                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={Echoes} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">Echoes</div>
                                <div className="genre">Adventure</div>
                            </div>
                        
                        </motion.div>

                        <motion.div className="box" variants={boxVAR}>
                        
                            <motion.div className="pic" whileHover={{scale: 1.1}} transition={{type:'spring', stiffness: 300}}><img src={See} alt="" /></motion.div>
                            <div className="content">
                                <div className="name">See</div>
                                <div className="genre">Fantasy</div>
                            </div>
                        
                        </motion.div>











































































                </motion.div>
            </div>
        </main>
     );
}
 

export default Movies;