import './title.css'
import { motion } from 'framer-motion'

const Title = ({title}) => {
    const titleVAR = {
        hidden:{
            x: '100vw',
        },
        visible:{
            x: 0,
            transition:{
                delay: .5,
                type: 'spring',
                mass: 1,
                stiffness: 100,
                duration: 1,
            }
        }
    }
    return ( 
        <main>
            <div className="title"><motion.p
            variants={titleVAR}
            initial="hidden"
            animate="visible"
            >{title}</motion.p></div>
        </main>
     );
}
 
export default Title;